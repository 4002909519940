.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.react-pdf__Document {
  width: 50vw

}
li {
  border-radius: 6px;
}

p{
  color: white;
}

label{
  color: white;
}

h3 {
  color: white;
}

h5 {
  color: white;
}

/* li:hover {
  background-color: #DEEDF0;

} */

/* li:hover{
  transition: ease-in-out all .1s; 
  -moz-transition: ease-in-out all .1s;
  -webkit-transition: ease-in-out all .1s;
  border: 1px white solid;
  text-decoration:none;
} */

nav a{
  text-decoration: none;
  color: white;
  position: relative;
  display:inline-block;
  border: none;
}

nav a::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  display:block;
  margin-top: 5px;
  left: 0;
  background-color: white;
  transition: width 0.4s ease-in-out;
}

nav a:hover::after {
  width: 100%
}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');

@media only screen and (max-width: 772px) {
  #horizontal-nav {
    display:none;
  }

}
@media only screen and (min-width: 772px) {
  #hamburger-dropdown {
    display:none;
  }
}


.text-title {
  font-family: 'Open Sans', sans-serif;
}

@keyframes fadeInUp {
  0% {
    transform: scale(0, .025);
  }
  50% {
    transform: scale(1, .025);
  }
}
@media (prefers-reduced-motion: no-preference) {
  .projects-sub-animation {
    animation:  2s 1;
  }
}

.projects-sub-animation {
  animation-name: fadeInUp;
  animation-duration: 1s;

}


.experience {
  background-color: #D2E2EC;

}



.row .card:hover {
  background-color: #EFEBE8;
}

.tesla-text-color {
  color: rgb(131, 13, 13);
  font-weight: bold;
  font-size: 14px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.navtabs{
  color: white;
}


.custom-message {
  font-size: 10px;

}

.doc-border {
  border-radius: 8px;

}