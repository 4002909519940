p {
  color: black;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
  text-indent: 3%;
}

h5{
  color: black;
}

h3{
  color: black;
}

.bold-text{
  font-weight:bold;
}